.footer {
  width: 100%;
  height: 306px;
  background-image: linear-gradient(
    0deg,
    rgba(13, 16, 24, 1) 0%,
    rgba(28, 34, 48, 1) 100%
  );
  position: relative;

  .footer-container {
    width: 1440px;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;

    .footer-item {
      padding-top: 60px;

      p {
        color: #fff;
        cursor: default;

        a {
          color: #fff;
        }

        &:not(:first-child) {
          line-height: 26px;
          color: #6c727d;
          margin: 0;
          a {
            color: #6c727d;
          }
        }

        &.phone {
          font-size: 18px;
          line-height: 28px;
        }

        img {
          margin-top: 8px;
        }
      }

      &:nth-child(1),
      &:nth-child(2) {
        p {
          &:not(:first-child) {
            &:hover {
              color: #b1b8c7;
              a {
                color: #b1b8c7;
              }
            }
          }
        }
      }

      &:nth-child(3) {
        p {
          &:nth-child(2) {
            &:hover {
              color: #b1b8c7;
              a {
                color: #b1b8c7;
              }
            }
          }
        }
      }
    }
  }

  .footer-copyright {
    width: 1440px;
    line-height: 52px;
    color: #6c727d;
    text-align: center;
    border-top: 1px solid rgba(30, 35, 45, 1);
    height: 52px;
    bottom: 0;
    position: absolute;
    left: calc((100% - 1440px) / 2);

    a {
      color: #b1b8c7;
    }
  }
}
