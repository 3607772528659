.path-wrapper {
  background-color: #f2f6fb;
  padding-top: 30px;

  .path-container {
    width: 1440px;
    margin: 0 auto;

    .path-top {
      position: relative;
      height: 30px;

      img {
        position: absolute;
        right: 1116px;
        bottom: -30px;
      }
    }

    .path-item {
      display: flex;

      .title-content {
        text-align: right;
        flex: 1 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 25px;

        p {
          margin: 0;
        }

        .title {
          width: fit-content;
          font-size: 30px;
          line-height: 30px;
          font-weight: bold;
          background: linear-gradient(
            to right,
            #015eea,
            #00c0fa
          );
          -webkit-background-clip: text;
          color: transparent;
        }

        .sub-title {
          font-size: 16px;
          color: #2d2f33;
          line-height: 30px;
        }
      }

      .radius {
        background-image: url('../../../assets/images/about/path/timeline-middle.png');
        background-repeat: repeat-y;
        background-position-x: center;
        width: 78px;

        img {
          position: relative;
        }
      }

      .path-content {
        width: 1080px;
        border-radius: 0 3px 3px 3px;
        background-color: #fff;
        box-shadow: 0px 1px 30px 0px
          rgba(112, 150, 207, 0.2);
        padding: 40px 32px;
        margin-top: 25px;

        div {
          font-size: 14px;
          line-height: 32px;
          display: flex;
          span {
            color: #7a7f89;
            // padding-right: 32px;
            width: 110px;
            white-space: nowrap;
          }
          p,
          ul {
            color: #2d2f33;
            margin: 0;
          }

          ul {
            padding-left: 0;
            list-style: none;

            li {
              &::before {
                content: ' ';
                display: inline-block;
                border-radius: 50%;
                background-color: #2d2f33;
                width: 6px;
                height: 6px;
                margin-right: 4px;
              }
            }
          }
        }
      }

      &:not(:nth-last-child(2)) {
        .path-content {
          margin-bottom: 40px;
        }
      }

      &:nth-last-child(2) {
        .radius {
            height: 40px;
        }
      }
    }

    .path-bottom {
      position: relative;
      height: 88px;

      img {
        position: absolute;
        right: 1116px;
        top: -145px;
      }
    }
  }
}
