.application-dev-wrapper {
  .banner {
    background-image: url('../../../assets/images/production/bigdata/banner2.1.png');
    
    .content {
      width: 610px!important;
    }
  }

  .application-dev-container {
    padding-top: 36px;

    .ant-tabs-nav {
      margin: 0 !important;

      &::before {
        display: none;
      }
    }

    .ant-tabs-tab {
      padding: 35px 0;

      & + .ant-tabs-tab {
        margin: 0 0 0 180px;
      }

      .application-label {
        &:hover {
          .active {
            display: block;
          }

          .normal {
            display: none;
          }
        }
      }

      .active {
        display: none;
      }

      .normal {
        display: block;
      }

      &.ant-tabs-tab-active {
        .active {
          display: block;
        }

        .normal {
          display: none;
        }
      }
    }

    .ant-tabs-ink-bar {
      height: 3px;
    }

    .introduction-container {
      position: relative;
      padding-top: 70px;

      & > img {
        position: absolute;
        right: 176px;
        top: -75px;
      }
    }

    .introduction-title {
      background-image: url('../../../assets/images/production/analyticalModeling/titlebg.png');

      .title-wrapper {
        padding-top: 48px !important;

        & > img {
          left: -101px;
        }
      }

      .title-container {
        width: 1440px !important;
        height: 344px !important;
        display: flex;
        padding: 16px;
        justify-content: space-between;

        & > div {
          position: unset !important;
          width: 813px;
          height: 291px;
          margin-left: 20px;
          padding-top: 72px !important;
          background-image: url('../../../assets/images/production/analyticalModeling/bg-item.png');

          p {
            margin-left: 96px;
            width: 657px;
          }
        }

        & > img {
          width: 553px;
        }
      }

      p {
        &:nth-child(1) {
          span {
            &:nth-child(1) {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .feature-container {
    & > p {
      height: 132px;
      margin: 0;
      position: relative;
      font-size: 28px;
      color: #0e1011;
      line-height: 132px;
      text-align: center;
    }

    .feature-content {
      display: flex;
      width: 1440px;
      margin: 0 auto;
      padding-bottom: 120px;

      .feature-item {
        width: 350px;
        height: 216px;
        background-color: #eef1f7;
        background-image: url('../../../assets/images/production/dataGovernance/featurebg.png');
        background-size: cover;

        &:not(:last-child) {
          margin-right: 13px;
        }

        p {
          width: 280px;

          &:nth-of-type(1) {
            color: #0e1011;
            font-size: 20px;
            margin-top: 24px;
          }

          &:nth-of-type(2) {
            margin-top: 0;
          }
        }
      }
    }
  }

  [id*='panel-2'] {
    .feature-item {
      &:nth-child(1) {
        padding-top: 46px;
        padding-left: 46px;

        .img {
          width: 41px;
          height: 30px;
          background-image: url('../../../assets/images/production/applicationDev/ACE-icon01.png');
        }
      }

      &:nth-child(2) {
        padding-top: 36px;
        padding-left: 45px;

        .img {
          width: 36px;
          height: 42px;
          background-image: url('../../../assets/images/production/applicationDev/ACE-icon02.png');
        }
      }

      &:nth-child(3) {
        padding-top: 48px;
        padding-left: 37px;

        .img {
          width: 34px;
          height: 30px;
          background-image: url('../../../assets/images/production/applicationDev/ACE-icon03.png');
        }
      }

      &:nth-child(4) {
        padding-top: 36px;
        padding-left: 42px;

        .img {
          width: 36px;
          height: 42px;
          background-image: url('../../../assets/images/production/applicationDev/ACE-icon04.png');
        }
      }

      &:hover {
        background-image: url('../../../assets/images/production/dataGovernance/featurebghover.png');
        cursor: default;

        p {
          color: #fff !important;
        }

        &:nth-child(1) {
          .img {
            background-image: url('../../../assets/images/production/applicationDev/ACE-icon01-hover.png');
          }
        }

        &:nth-child(2) {
          .img {
            background-image: url('../../../assets/images/production/applicationDev/ACE-icon02-hover.png');
          }
        }

        &:nth-child(3) {
          .img {
            background-image: url('../../../assets/images/production/applicationDev/ACE-icon03-hover.png');
          }
        }

        &:nth-child(4) {
          .img {
            background-image: url('../../../assets/images/production/applicationDev/ACE-icon04-hover.png');
          }
        }
      }
    }
  }

  [id*='panel-1'] {
    .feature-item {
      &:nth-child(1) {
        padding-top: 46px;
        padding-left: 46px;

        .img {
          width: 41px;
          height: 30px;
          background-image: url('../../../assets/images/production/applicationDev/BOSS-icon01.png');
        }
      }

      &:nth-child(2) {
        padding-top: 36px;
        padding-left: 45px;

        .img {
          width: 36px;
          height: 42px;
          background-image: url('../../../assets/images/production/applicationDev/BOSS-icon02.png');
        }
      }

      &:nth-child(3) {
        padding-top: 45px;
        padding-left: 36px;

        .img {
          width: 32px;
          height: 32px;
          background-image: url('../../../assets/images/production/applicationDev/BOSS-icon03.png');
        }
      }

      &:nth-child(4) {
        padding-top: 39px;
        padding-left: 38px;

        .img {
          width: 38px;
          height: 38px;
          background-image: url('../../../assets/images/production/applicationDev/BOSS-icon04.png');
        }
      }

      &:hover {
        background-image: url('../../../assets/images/production/dataGovernance/featurebghover.png');
        cursor: default;

        p {
          color: #fff !important;
        }

        &:nth-child(1) {
          .img {
            background-image: url('../../../assets/images/production/applicationDev/BOSS-icon01-hover.png');
          }
        }

        &:nth-child(2) {
          .img {
            background-image: url('../../../assets/images/production/applicationDev/BOSS-icon02-hover.png');
          }
        }

        &:nth-child(3) {
          .img {
            background-image: url('../../../assets/images/production/applicationDev/BOSS-icon03-hover.png');
          }
        }

        &:nth-child(4) {
          .img {
            background-image: url('../../../assets/images/production/applicationDev/BOSS-icon04-hover.png');
          }
        }
      }
    }
  }
}
