.about-wrapper {
  position: relative;
  min-width: 1440px;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-image: url('../../assets/images/about/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-family: 'MicrosoftYaHei-Bold';
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 18px;
      color: #5a5e66;
      margin-bottom: 26px;
    }

    .see-detail {
      width: 112px;
      height: 40px;
      background-image: linear-gradient(
          90deg,
          rgba(51, 112, 237, 1) 0%,
          rgba(0, 192, 250, 1) 100%
        ),
        linear-gradient(
          rgba(51, 112, 237, 1),
          rgba(0, 192, 250, 1)
        );
      background-blend-mode: normal, normal;
      font-size: 16px;
      color: #fff;
      line-height: 40px;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .ant-tabs-tab {
    width: 240px;
    height: 64px;
    margin: 0 !important;
    font-size: 18px;

    .ant-tabs-tab-btn {
      color: #2d2f33;
      width: 100%;
      text-align: center;
    }

    &.ant-tabs-tab-active,
    &:hover {
      .ant-tabs-tab-btn {
        color: #3370ed;
      }
    }
  }

  .ant-tabs-nav {
    transition: all 0.3s ease-in-out;
    margin: 0 !important;
  }

  .ant-tabs-content-holder {
    &.contract {
      background-image: url('../../assets/images/about/contract/bg.png');
      background-size: cover;
      min-width: 1440px;
      background-position: center center;
    }
  }

  .about-container {
    &.fixed-tabs {
      & > .ant-tabs {
        & > .ant-tabs-nav {
          transition: all 0.3s ease-in-out;
          position: fixed;
          width: 100%;
          top: 64px;
          z-index: 99;
          background: #fff;
          border-top: 1px solid #ececed;
        }

        & > .ant-tabs-content-holder {
          padding-top: 64px;
        }
      }
    }
  }
}

.video-wrapper {
  .ant-modal {
    width: 960px !important;
    padding-bottom: 0 !important;
    // transform-origin: unset !important;
    // height: 540px!important;

    .ant-modal-content {
      padding: 0 !important;
      height: 100% !important;
      background: transparent !important;
      box-shadow: none !important;

      .ant-modal-body {
        height: 100% !important;
      }

      .ant-modal-close-icon {
        color: #fff !important;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}

.ant-modal-close:focus,
.ant-modal-close:hover {
  outline: none !important;
}

.ant-modal-close {
  top: 8px !important;
  right: 12px !important;
}
