.hotline,
.backtop {
  position: fixed;
  right: 14px;
  z-index: 99;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  text-align: center;
  line-height: 44px;
  opacity: 0.9;
  box-shadow: 0px 1px 16px 0px rgba(138, 180, 228, 0.3);
  cursor: pointer;
}

.hotline {
  bottom: 174px;
  background-image: linear-gradient(
    161deg,
    rgba(243, 82, 18, 1) 0%,
    rgba(250, 142, 45, 1) 100%
  );

  img {
    transform: rotateY(180deg);
    opacity: 0.9;
  }
}

.backtop {
  bottom: 120px;
  background-color: #fff;
  background-image: linear-gradient(
    161deg,
    rgba(224, 230, 240, 1) 0%,
    rgba(245, 247, 250, 1) 100%
  );
  transition: all 0.3s ease-in-out;

  &:hover {
    background-image: none;
    transition: all 0.3s ease-in-out;
  }
}

.supprt-line {
  position: fixed;
  right: 68px;
  bottom: 17px;
  z-index: 99;
  //   display: none;
  width: 150px;
  border-radius: 3px;
  background: #fff;
  padding: 14px;
  box-shadow: 0px 5px 20px 0px rgba(69, 104, 186, 0.11);
  transition: opacity 0.3s;
  opacity: 0;

  &::after {
    display: block;
    content: ' ';
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-left: 8px solid #fff;
    position: fixed;
    bottom: 190px;
    right: 52px;
  }

  & > p {
    margin: 0;

    &:nth-child(1) {
      line-height: 21px;
      color: #0e1011;
    }

    &:nth-child(2) {
      font-size: 16px;
      color: #fd4f00;
    }
  }

  .code-container {
    margin-top: 5px;
    padding-top: 10px;
    border-top: 1px solid #d7dce7;

    img {
      margin-bottom: 5px;
    }

    p {
      margin: 0;
      color: #0e1011;
      line-height: 21px;
    }
  }
}
