.smart-wrapper {
  position: relative;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-image: url('../../../assets/images/solution/smart/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-family: 'MicrosoftYaHei-Bold';
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 26px;
      color: #878d99;
      width: 580px;
      margin-bottom: 26px;
    }
  }

  .introduction-wrapper,
  .achievement-wrapper {
    .title {
      font-size: 28px;
      color: #2e2f32;
      line-height: 28px;
      margin: 0 auto;
      text-align: center;
      padding-top: 80px;
      padding-bottom: 40px;
    }
  }

  .introduction-wrapper {
    height: 688px;
    background-image: url('../../../assets/images/solution/smart/bg1.png');
    background-size: cover;
    padding: 0;

    .introduction-title {
      width: 1440px;
      height: 460px;
      background-image: url('../../../assets/images/solution/smart/card.png');
      background-size: cover;
      margin: 0 auto;
      padding-top: 113px;
      padding-left: 788px;

      p {
        padding-left: 22px;

        &:nth-of-type(1) {
          font-size: 24px;
          line-height: 24px;
          color: #fff;
          margin-top: -16px;
        }

        &:nth-of-type(2) {
          width: 492px;
          font-size: 16px;
          line-height: 26px;
          color: #b4bccc;
          margin-bottom: -7px;
        }
      }

      img {
        &:nth-of-type(2) {
          margin-left: 22px;
        }
      }
    }
  }

  .achievement-container {
    width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px;

    .title {
      padding-top: 78px;
      padding-bottom: 48px;
    }

    .achievement-item {
      width: 472px;
      height: 287px;
      background-image: url('../../../assets/images/solution/smart/bg.png');
      background-size: cover;
      padding-left: 40px;
      padding-top: 49px;
      transition: all 0.3s ease-in-out;

      p {
        margin: 0;
      }

      .sub-title {
        font-size: 22px;
        line-height: 22px;
        color: #0e1011;
        padding-top: 26px;
        padding-bottom: 29px;
      }

      .sub-content {
        font-size: 14px;
        color: #5a5e66;
        max-width: 280px;
      }

      & > div {
        display: flex;

        div {
          &:first-child {
            border-right: 1px solid #cfd6e4;
            padding-right: 22px;
          }

          &:last-child {
            padding-left: 22px;
          }
        }
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      &:nth-child(1) {
        .img {
          width: 40px;
          height: 36px;
          background-image: url('../../../assets/images/solution/smart/logo1.png');
        }
      }

      &:nth-child(2) {
        .img {
          width: 38px;
          height: 36px;
          background-image: url('../../../assets/images/solution/smart/logo2.png');
        }
      }

      &:nth-child(3) {
        .img {
          width: 41px;
          height: 34px;
          background-image: url('../../../assets/images/solution/smart/logo3.png');
        }
      }

      &:hover {
        background-image: url('../../../assets/images/solution/smart/bg-hover.png');
        transition: all 0.3s ease-in-out;

        .sub-title,
        .sub-content {
          color: #fff;
        }

        &:nth-child(1) {
          .img {
            background-image: url('../../../assets/images/solution/smart/logo1-hover.png');
          }
        }

        &:nth-child(2) {
          .img {
            background-image: url('../../../assets/images/solution/smart/logo2-hover.png');
          }
        }

        &:nth-child(3) {
          .img {
            background-image: url('../../../assets/images/solution/smart/logo3-hover.png');
          }
        }
      }
    }
  }
}
