.honor-wrapper {
  position: relative;
  padding-top: 30px;
  background-color: #f2f6fb;

  & > img {
    position: absolute;
    top: -4px;
    left: 240px;
  }

  .honor-container {
    width: 1440px;
    margin: 0 auto;
    padding-bottom: 120px;
    position: relative;

    & > img {
      position: absolute;
      top: -35px;
    }

    .honor-top {
      position: relative;
      height: 30px;

      img {
        position: absolute;
        right: 969px;
        bottom: -30px;
      }
    }

    .honor-item {
      display: flex;

      .title {
        flex: 1;
        width: 216px;
        font-weight: bold;
        font-size: 24px;
        color: #2d2f33;
        // margin-right: 24px;
        text-align: right;
        padding-top: 18px;
      }

      .radius {
        background-image: url('../../../assets/images/about/path/timeline-middle.png');
        background-repeat: repeat-y;
        background-position-x: center;
        width: 78px;

        img {
          position: relative;
        }
      }

      .honor-content {
        width: 934px;
        padding: 40px;
        background-color: #fff;
        border-radius: 0 3px 3px 3px;
        box-shadow: 0px 1px 30px 0px
          rgba(112, 150, 207, 0.2);
        margin-top: 26px;

        p {
          display: inline-block;
          width: 50%;
          font-size: 16px;
          color: #5a5e66;
          line-height: 36px;
          margin: 0;
        }
      }

      &:not(:nth-last-child(2)) {
        .honor-content {
          margin-bottom: 35px;
        }
      }

      &:nth-last-child(2) {
        .radius {
          height: 40px;
        }
      }
    }

    .honor-bottom {
      position: relative;
      height: 88px;

      img {
        position: absolute;
        right: 969px;
        top: -193px;
      }
    }
  }
}
