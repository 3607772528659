.insurance-wrapper {
  position: relative;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-image: url('../../../assets/images/solution/insurance/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-family: 'MicrosoftYaHei-Bold';
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 26px;
      color: #878d99;
      width: 580px;
      margin-bottom: 26px;
    }
  }

  .introduction-wrapper,
  .achievement-wrapper {
    .title {
      font-size: 28px;
      color: #2e2f32;
      line-height: 28px;
      margin: 0 auto;
      text-align: center;
      padding-top: 80px;
      padding-bottom: 40px;
    }
  }

  .introduction-wrapper {
    height: 688px;
    background-image: url('../../../assets/images/solution/insurance/bg1.png');
    background-size: cover;
    padding: 0;

    .introduction-title {
      width: 1440px;
      height: 460px;
      background-image: url('../../../assets/images/solution/insurance/card1.png');
      background-size: cover;
      margin: 0 auto;
      padding-top: 90px;
      padding-left: 788px;

      p {
        padding-left: 22px;

        &:nth-of-type(1) {
          font-size: 24px;
          line-height: 24px;
          color: #fff;
          margin-top: -16px;
        }

        &:nth-of-type(2) {
          width: 492px;
          font-size: 16px;
          line-height: 26px;
          color: #b4bccc;
          margin-bottom: -7px;
        }
      }

      img {
        &:nth-of-type(2) {
          margin-left: 22px;
        }
      }
    }
  }

  .achievement-wrapper {
    height: 729px;
    background-image: url('../../../assets/images/solution/insurance/bg2.png');
    background-size: cover;

    .achievement-container {
      width: 1440px;
      height: 500px;
      margin: 0 auto;
      background-image: url('../../../assets/images/solution/insurance/card2.png');
      background-size: cover;
      padding-top: 50px;
      padding-left: 62px;
      box-shadow: 0px 1px 38px 0px rgba(112, 150, 207, 0.35);

      p {
        padding-left: 68px;

        &:nth-of-type(1) {
          font-size: 24px;
          line-height: 24px;
          color: #2e2f32;
          margin-top: -16px;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          width: 467px;
          font-size: 14px;
          line-height: 21px;
          color: #5a5e66;
          margin-bottom: 8px;
          text-align: justify;
        }
      }
    }
  }
}
