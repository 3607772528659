.solution-wrapper {
  position: relative;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-image: url('../../assets/images/solution/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-family: 'MicrosoftYaHei-Bold';
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 18px;
      color: #5a5e66;
      // width: 500px;
      margin-bottom: 26px;
    }
  }

  .solution-content {
    padding-bottom: 80px;

    & > p {
      color: #0e1011;
      font-size: 28px;
      line-height: 28px;
      text-align: center;
      padding: 80px 0 40px 0;
      margin-bottom: 0;
    }

    .solution-container {
      width: 1440px;
      display: flex;
      margin: 0 auto;

      .solution-left {
        display: flex;
        flex-wrap: wrap;
        margin-right: 12px;

        .solution-item {
          width: calc((100% - 24px) / 3);

          &:nth-child(1) {
            margin-right: 12px;
            margin-bottom: 12px;
            background-image: url('../../assets/images/solution/card1.png');

            &:hover {
              background-image: url('../../assets/images/solution/card1-hover.png');
            }
          }

          &:nth-child(2) {
            margin-right: 12px;
            margin-bottom: 12px;
            background-image: url('../../assets/images/solution/card2.png');

            &:hover {
              background-image: url('../../assets/images/solution/card2-hover.png');
            }
          }

          &:nth-child(3) {
            margin-bottom: 12px;
            background-image: url('../../assets/images/solution/card3.png');

            &:hover {
              background-image: url('../../assets/images/solution/card3-hover.png');
            }
          }

          &:nth-child(4) {
            margin-right: 12px;
            background-image: url('../../assets/images/solution/card4.png');

            &:hover {
              background-image: url('../../assets/images/solution/card4-hover.png');
            }
          }

          &:nth-child(5) {
            margin-right: 12px;
            background-image: url('../../assets/images/solution/card5.png');

            &:hover {
              background-image: url('../../assets/images/solution/card5-hover.png');
            }
          }

          &:nth-child(6) {
            background-image: url('../../assets/images/solution/card6.png');

            &:hover {
              background-image: url('../../assets/images/solution/card6-hover.png');
            }
          }
        }
      }
      .solution-right {
        width: 324px;
        height: 492px;
        flex-shrink: 0;

        .solution-item {
          height: 100%;
          background-image: url('../../assets/images/solution/card7.png');
          background-size: cover;

          &:hover {
            background-image: url('../../assets/images/solution/card7-hover.png');
          }
        }
      }

      .solution-item {
        padding: 32px;
        background-size: cover;
        transition: all 0.3s ease-in-out;

        .title {
          color: #2d2f33;
          font-size: 20px;
          line-height: 20px;
          font-weight: bold;
        }

        p {
          color: #878d99;
          font-size: 14px;
          line-height: 24px;
          margin-top: 20px;
          margin-bottom: 40px;
        }

        .see-detail {
          font-size: 14px;
          color: #3370ed;
          transition: all 0.3s ease-in-out;

          span {
            transition: all 0.3s ease-in-out;
            display: none;
            padding-right: 6px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:hover {
          cursor: default;
          transition: all 0.3s ease-in-out;
          p {
            color: #5a5e66;
          }
          .see-detail {
            transition: all 0.3s ease-in-out;
            span {
              transition: all 0.3s ease-in-out;
              display: inline;
            }
          }
        }
      }
    }
  }
}
