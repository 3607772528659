.home {
  position: relative;
  min-width: 1440px;

  .banner {
    min-width: 1440px;
    height: 568px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    padding-left: 238px;
    padding-top: 172px;

    &.banner-1 {
      background-image: url('../../assets/images/banner/banner01.png');
    }

    &.banner-2 {
      background-image: url('../../assets/images/banner/banner02.png');
    }

    &.banner-3 {
      background-image: url('../../assets/images/banner/banner03.png');
    }

    &.banner-4 {
      background-image: url('../../assets/images/banner/banner04.png');
    }

    .title {
      font-family: 'MicrosoftYaHei-Bold';
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 18px;
      color: rgba(45, 47, 51, 0.8);
      width: 560px;
      margin-bottom: 26px;
    }

    .see-detail {
      width: 112px;
      height: 40px;
      background-image: linear-gradient(
          90deg,
          rgba(51, 112, 237, 1) 0%,
          rgba(0, 192, 250, 1) 100%
        ),
        linear-gradient(
          rgba(51, 112, 237, 1),
          rgba(0, 192, 250, 1)
        );
      background-blend-mode: normal, normal;
      font-size: 16px;
      color: #fff;
      line-height: 40px;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .slick-dots-bottom {
    width: 240px;
    left: 234px !important;
    bottom: 127px !important;
    margin: 0 !important;

    li {
      flex: 1 !important;
      width: unset !important;

      button {
        background: #bbbecf !important;
      }

      &.slick-active {
        width: unset !important;

        button {
          background: #3370ed !important;
        }
      }
    }
  }

  .recommend {
    position: absolute;
    top: 525px;
    left: calc((100% - 1440px) / 2);
    display: flex;
    z-index: 1;

    .recommend-item {
      width: 464px;
      height: 80px;
      background: #fff;
      box-shadow: 0px 5px 20px 0px rgba(69, 104, 186, 0.11);
      border-radius: 3px;

      &:hover {
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-right: 24px;
      }

      img {
        vertical-align: top;
      }

      .recommend-content {
        display: inline-block;
        width: calc(100% - 90px);
        padding-left: 20px;
        padding-top: 24px;

        p {
          margin: 0;

          &:nth-child(1) {
            font-family: 'MicrosoftYaHei-Bold';
            font-weight: bold;
            color: #0e1011;
          }

          &:nth-child(2) {
            color: #878d99;
          }
        }
      }
    }
  }

  .production,
  .solution,
  .about {
    .title {
      text-align: center;
      font-size: 28px;
      color: #0e1011;
      margin-bottom: 6px;
    }

    .sub-title {
      color: #878d99;
      text-align: center;
      margin-bottom: 35px;

      a {
        color: #3370ed;
        padding-left: 8px;
      }
    }
  }

  .production {
    height: 800px;
    padding-top: 136px;

    .production-container {
      display: flex;
      // width: fit-content;
      // margin: 0 auto;
      background-image: url('../../assets/images/home/bg.png');
      background-size: cover;
      justify-content: center;

      .production-item {
        width: 360px;
        height: 544px;
        background-size: cover;

        display: flex;
        flex-direction: column-reverse;

        &:nth-child(2n) {
          flex-direction: column;
        }

        .production-item-container {
          position: relative;
          transition: all 0.3s ease-in-out;
          background-image: url('../../assets/images/home/textbg.png');
          background-size: cover;
          height: 224px;
          padding-top: 46px;
          padding-left: 54px;

          .production-icon {
            width: 40px;
            text-align: center;
            margin-bottom: 24px;

            img {
              &:nth-child(1) {
                display: block;
              }

              &:nth-child(2) {
                display: none;
              }
            }
          }

          .production-title {
            font-family: 'MicrosoftYaHei-Bold';
            font-weight: bold;
            color: #2b3032;
            font-size: 24px;
            margin-bottom: 20px;
          }

          .production-content {
            height: 0;
            opacity: 0;
            margin-bottom: 20px;
          }

          .production-info {
            color: rgba(0, 0, 0, 0.6);

            span {
              padding: 0 16px;
              display: inline-block;
              height: 14px;
              line-height: 14px;

              &:first-child {
                padding-left: 0;
              }

              &:not(:last-child) {
                border-right: 1px solid rgba(0, 0, 0, 0.6);
              }
            }
          }

          // &:hover {
          //   padding: 40px 34px;
          //   background-image: url('../../assets/images/home/textbghover.png');
          //   background-size: cover;
          //   height: 359px;
          //   cursor: pointer;
          //   color: rgba(255, 255, 255, 0.6);
          //   transition: all 0.3s ease-in-out;

          //   .production-icon {
          //     img {
          //       &:nth-child(1) {
          //         display: none;
          //       }

          //       &:nth-child(2) {
          //         display: block;
          //       }
          //     }
          //   }

          //   .production-title {
          //     color: #fff;
          //   }
          //   .production-info {
          //     color: rgba(255, 255, 255, 0.6);

          //     span {
          //       display: inline-block;
          //       height: 14px;
          //       line-height: 14px;

          //       &:not(:last-child) {
          //         border-right: 1px solid
          //           rgba(255, 255, 255, 0.4);
          //       }
          //     }
          //   }

          //   .production-content {
          //     color: rgba(255, 255, 255, 0.6);
          //     height: fit-content;
          //     opacity: 1;
          //     transition: opacity 0.8s;
          //   }
          // }
        }

        &:nth-child(1) {
          background-image: url('../../assets/images/home/01bg.png');
        }
        &:nth-child(2) {
          background-image: url('../../assets/images/home/02bg.png');
        }
        &:nth-child(3) {
          background-image: url('../../assets/images/home/03bg.png');
        }
        &:nth-child(4) {
          background-image: url('../../assets/images/home/04bg.png');
        }

        &:hover {
          .production-item-container {
            padding: 40px 34px;
            background-image: url('../../assets/images/home/textbghover.png');
            background-size: cover;
            height: 359px;
            cursor: pointer;
            color: rgba(255, 255, 255, 0.6);
            transition: all 0.3s ease-in-out;

            .production-icon {
              img {
                &:nth-child(1) {
                  display: none;
                }

                &:nth-child(2) {
                  display: block;
                }
              }
            }

            .production-title {
              color: #fff;
            }
            .production-info {
              color: rgba(255, 255, 255, 0.6);

              span {
                display: inline-block;
                height: 14px;
                line-height: 14px;

                &:not(:last-child) {
                  border-right: 1px solid
                    rgba(255, 255, 255, 0.4);
                }
              }
            }

            .production-content {
              color: rgba(255, 255, 255, 0.6);
              height: fit-content;
              opacity: 1;
              transition: opacity 0.8s;
            }
          }
        }
      }
    }
  }

  .solution {
    height: 784px;
    padding-top: 100px;

    .solution-container {
      max-width: 100%;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;

      .solution-item {
        flex: 0 1 auto;
        width: calc((100% - 3px) / 4);
        padding: 48px 54px;
        height: 292px;
        position: relative;
        transition: all 0.3s ease 0s;
        transition: opacity 0.3s ease 0s;

        &:not(:nth-child(4n)) {
          margin-right: 1px;
        }

        &:nth-child(1) {
          background-image: url('../../assets/images/home/model1.png');
          margin-bottom: 1px;
        }
        &:nth-child(2) {
          background-image: url('../../assets/images/home/model2.png');
          margin-bottom: 1px;
        }
        &:nth-child(3) {
          background-image: url('../../assets/images/home/model3.png');
          margin-bottom: 1px;
        }
        &:nth-child(4) {
          background-image: url('../../assets/images/home/model4.png');
          margin-bottom: 1px;
        }
        &:nth-child(5) {
          background-image: url('../../assets/images/home/model5.png');
        }
        &:nth-child(6) {
          background-image: url('../../assets/images/home/model6.png');
        }
        &:nth-child(7) {
          background-image: url('../../assets/images/home/model7.png');
        }
        &:nth-child(8) {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url('../../assets/images/home/solutionallbg.png');
          }

          a {
            color: #3370ed;
          }
        }

        .solution-title {
          font-family: 'MicrosoftYaHei-Bold';
          font-weight: bold;
          font-size: 20px;
          color: #2e2f32;
          margin-bottom: 14px;
          transition: all 0.3s ease 0s;
        }

        .solution-content {
          height: 136px;
          line-height: 20px;
          color: #878d99;
          transition: all 0.3s ease 0s;
        }

        .solution-detail {
          line-height: 20px;
          color: #3370ed;
          cursor: pointer;
          transition: background-color 0.3s ease 0s;
          transition: color 0.3s ease 0s;
        }

        .solution-all-detail {
          position: absolute;
          right: 72px;
          bottom: 48px;
          font-size: 20px;
          color: #3370ed;

          img {
            margin-left: 24px;
          }
        }

        &:hover {
          transition: background-color 0.3s ease 0s;
        }

        &:not(:last-child) {
          &:hover {
            transition: all 0.3s ease 0s;

            &:nth-child(1) {
              background-image: url('../../assets/images/home/model1-hover.png');
            }
            &:nth-child(2) {
              background-image: url('../../assets/images/home/model2-hover.png');
            }
            &:nth-child(3) {
              background-image: url('../../assets/images/home/model3-hover.png');
            }
            &:nth-child(4) {
              background-image: url('../../assets/images/home/model4-hover.png');
            }
            &:nth-child(5) {
              background-image: url('../../assets/images/home/model5-hover.png');
            }
            &:nth-child(6) {
              background-image: url('../../assets/images/home/model6-hover.png');
            }
            &:nth-child(7) {
              background-image: url('../../assets/images/home/model7-hover.png');
            }

            .solution-title,
            .solution-content {
              color: #fff;
              transition: all 0.3s ease 0s;
            }

            .solution-detail {
              width: 95px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              border: solid 1px rgba(255, 255, 255, 0.5);
              color: #fff;
              margin-top: -8px;
              transition: background-color 0.3s ease 0s;
              transition: color 0.3s ease 0s;

              &:hover {
                transition: background-color 0.3s ease 0s;
                transition: color 0.3s ease 0s;
                background-color: #fff;
                color: #3370ed;
              }
            }
          }
        }

        &:last-child {
          &:hover {
            transition: opacity 0.3s ease 0s;
            &::before {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  .about {
    height: 850px;
    background-image: url('../../assets/images/home/aboutbg.png');
    padding-top: 110px;

    .sub-title {
      margin-bottom: 120px;
    }

    .about-container {
      padding-left: 240px;

      .about-content {
        width: 518px;
        color: #2e2f32;
        margin-bottom: 32px;
      }

      .about-tatistics {
        display: flex;
        flex-wrap: wrap;
        width: 380px;

        .about-statistics-item {
          width: 190px;
          margin-bottom: 56px;

          p {
            color: #878d99;
            margin: 0;

            span {
              &:nth-child(1) {
                font-size: 40px;
                color: #0e1011;
              }

              &:nth-child(2) {
                font-size: 28px;
                color: #424447;
              }
            }
          }
        }
      }
    }
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  // position: absolute;
  // top: 280px!important;
  z-index: 10;
  display: block;
  width: 32px !important;
  height: 58px !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.ant-carousel .slick-prev {
  left: 28px !important;
  background-image: url('../../assets/images/banner/arrow-l.png') !important;

  &:hover {
    background-image: url('../../assets/images/banner/arrow-l-hover.png') !important;
  }
}

.ant-carousel .slick-next {
  right: 28px !important;
  background-image: url('../../assets/images/banner/arrow-r.png') !important;

  &:hover {
    background-image: url('../../assets/images/banner/arrow-r-hover.png') !important;
  }
}
