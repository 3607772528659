.analytical-model-wrapper {
  .banner {
    background-image: url('../../../assets/images/production/bigdata/banner2.1.png');

    .content {
      width: 610px!important;
    }
  }

  .analytical-model-container {
    .introduction-title {
      background-image: url('../../../assets/images/production/analyticalModeling/titlebg.png');

      .title-wrapper {
        padding-top: 48px !important;

        & > img {
          left: -101px;
        }
      }

      .title-container {
        width: 1440px !important;
        height: 344px !important;
        display: flex;
        padding: 16px;
        justify-content: space-between;

        & > div {
          position: unset !important;
          width: 813px;
          height: 291px;
          margin-left: 20px;
          padding-top: 72px !important;
          background-image: url('../../../assets/images/production/analyticalModeling/bg-item.png');

          p {
            margin-left: 96px;
            width: 657px;
          }
        }

        & > img {
          width: 553px;
        }
      }
    }

    .feature-container {
      position: relative;
      padding-top: 20px;

      & > p {
        height: 132px;
        margin: 0;
        position: relative;
        font-size: 28px;
        color: #0e1011;
        line-height: 132px;
        text-align: center;
      }

      .feature-content {
        display: flex;
        width: 1440px;
        margin: 0 auto;
        padding-bottom: 88px;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;

        .feature-item {
          width: calc((100% - 36px) / 2);
          height: 242px;
          background-color: #eef1fa;
          background-image: url('../../../assets/images/production/analyticalModeling/featurebg.png');
          background-size: cover;
          padding: 70px 130px 0 46px;

          &:nth-child(1),
          &:nth-child(3) {
            margin-right: 36px;
          }

          &:nth-child(1),
          &:nth-child(2) {
            margin-bottom: 26px;
          }

          p {
            &:nth-child(1) {
              font-size: 24px;
              line-height: 22px;
            }

            &:nth-child(2) {
              font-size: 14px;
              line-height: 24px;
            }
          }

          &:hover {
            background-image: url('../../../assets/images/production/analyticalModeling/featurebg-hover.png');
            background-color: #3370ed;
            cursor: default;

            p {
              color: #fff !important;
            }
          }
        }
      }

      & > img {
        position: absolute;
        right: 57px;
        top: 60px;
      }
    }
  }
}
