.anti-wrapper {
  position: relative;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-image: url('../../../assets/images/solution/anti/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-family: 'MicrosoftYaHei-Bold';
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 26px;
      color: #878d99;
      width: 580px;
      margin-bottom: 26px;
    }
  }

  .introduction-wrapper,
  .achievement-wrapper {
    .title {
      font-size: 28px;
      color: #2e2f32;
      line-height: 28px;
      margin: 0 auto;
      text-align: center;
      padding-top: 80px;
      padding-bottom: 40px;
    }
  }

  .introduction-wrapper {
    height: 688px;
    background-image: url('../../../assets/images/solution/anti/bg1.png');
    background-size: cover;
    padding: 0;

    .introduction-title {
      width: 1440px;
      height: 460px;
      background-image: url('../../../assets/images/solution/anti/card1.png');
      background-size: cover;
      margin: 0 auto;
      padding-top: 113px;
      padding-left: 788px;

      p {
        padding-left: 22px;

        &:nth-of-type(1) {
          font-size: 24px;
          line-height: 24px;
          color: #fff;
          margin-top: -16px;
        }

        &:nth-of-type(2) {
          width: 492px;
          font-size: 16px;
          line-height: 26px;
          color: #b4bccc;
          margin-bottom: -7px;
        }
      }

      img {
        &:nth-of-type(2) {
          margin-left: 22px;
        }
      }
    }
  }

  .achievement-wrapper {
    height: 431px;
    background-image: url('../../../assets/images/solution/anti/bg2.png');
    background-size: cover;

    & > .title {
      color: #fff;
    }

    .achievement-container {
      width: 1440px;
      margin: 0 auto;
      display: flex;

      .achievement-item {
        width: 464px;
        height: 203px;
        background-image: url('../../../assets/images/solution/anti/card2.png');
        background-size: cover;
        padding-top: 40px;
        text-align: center;

        &:not(:last-child) {
          margin-right: 24px;
        }

        .title {
          font-size: 24px;
          font-weight: bold;
          line-height: 24px;
          padding: 0 !important;
        }

        img {
          margin: 24px auto;
        }

        .content {
          max-width: 398px;
          margin: 0 auto;
          color: #5a5e66;
          font-size: 16px;
        }
      }
    }
  }
}
