.smart-gover-wrapper {
  position: relative;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-image: url('../../../assets/images/solution/government/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-family: 'MicrosoftYaHei-Bold';
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 26px;
      color: #878d99;
      width: 580px;
      margin-bottom: 26px;
    }
  }

  .introduction-wrapper,
  .achievement-wrapper {
    .title {
      font-size: 28px;
      color: #2e2f32;
      line-height: 28px;
      margin: 0 auto;
      text-align: center;
      padding-top: 80px;
      padding-bottom: 40px;
    }
  }

  .introduction-wrapper {
    height: 688px;
    background-image: url('../../../assets/images/solution/smart/bg1.png');
    background-size: cover;
    padding: 0;

    .introduction-title {
      width: 1440px;
      height: 460px;
      background-image: url('../../../assets/images/solution/government/card.png');
      background-size: cover;
      margin: 0 auto;
      padding-top: 113px;
      padding-left: 788px;

      p {
        padding-left: 22px;

        &:nth-of-type(1) {
          font-size: 24px;
          line-height: 24px;
          color: #fff;
          margin-top: -16px;
        }

        &:nth-of-type(2) {
          width: 492px;
          font-size: 16px;
          line-height: 26px;
          color: #b4bccc;
          margin-bottom: -7px;
        }
      }

      img {
        &:nth-of-type(2) {
          margin-left: 22px;
          margin-top: 24px;
        }
      }
    }
  }

  .introduction-content {
    width: 1440px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;

    .introduction-item {
      width: calc((100% - 36px) / 4);
      height: 216px;
      padding-top: 22px;
      padding-left: 40px;
      background-image: url('../../../assets/images/solution/government/card2.png');
      background-size: cover;
      transition: all 0.3s ease-in-out;

      &:not(:last-child) {
        margin-right: 12px;
      }

      .sub-title {
        font-size: 22px;
        line-height: 22px;
        color: #0e1011;
        padding-top: 30px;
        padding-bottom: 20px;
        margin-bottom: 0;
      }

      .sub-content {
        font-size: 14px;
        color: #5a5e66;
        max-width: 280px;
      }

      &:nth-child(1) {

        .img {
          width: 36px;
          height: 42px;
          background-image: url('../../../assets/images/solution/government/logo1.png');
          margin-top: 2px;
        }
      }

      &:nth-child(2) {
        .img {
          width: 35px;
          height: 33px;
          background-image: url('../../../assets/images/solution/government/logo2.png');
          margin-top: 11px;
        }
      }

      &:nth-child(3) {

        .img {
          width: 39px;
          height: 39px;
          background-image: url('../../../assets/images/solution/government/logo3.png');
          margin-top: 5px;
        }
      }

      &:nth-child(4) {

        .img {
          width: 40px;
          height: 44px;
          background-image: url('../../../assets/images/solution/government/logo4.png');
        }
      }

      &:hover {
        background-image: url('../../../assets/images/solution/government/card2-hover.png');
        transition: all 0.3s ease-in-out;

        &:nth-child(1) {
          .img {
            background-image: url('../../../assets/images/solution/government/logo1-hover.png');
          }
        }

        &:nth-child(2) {
          .img {
            background-image: url('../../../assets/images/solution/government/logo2-hover.png');
          }
        }

        &:nth-child(3) {
          .img {
            background-image: url('../../../assets/images/solution/government/logo3-hover.png');
          }
        }

        &:nth-child(4) {
          .img {
            background-image: url('../../../assets/images/solution/government/logo4-hover.png');
          }
        }

        .sub-title,
        .sub-content {
          color: #fff;
        }
      }
    }
  }

  .achievement-wrapper {
    height: 725px;
    background-image: url('../../../assets/images/solution/government/bg2.png');
    background-size: cover;

    .title {
      color: #fff;
    }

    .achievement-container {
      display: flex;
      flex-wrap: wrap;
      width: 1440px;
      margin: 0 auto;
    }

    .achievement-item {
      height: 236px;
      text-align: center;
      padding-top: 40px;
      background-size: cover;
      box-shadow: 0px 2px 30px 0px rgba(35, 79, 209, 0.5);

      span {
        display: block;
        margin: 0;
        padding: 0;

        &.title {
          font-size: 24px;
          line-height: 36px;
          color: #2e2f32;
          font-weight: bold;
        }

        &.sub-title {
          font-size: 24px;
          color: #2e2f32;
        }

        &.content {
          font-size: 16px;
          color: #5a5e66;
        }
      }

      & > img {
        margin: 20px auto;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: 464px;
        background-image: url('../../../assets/images/solution/government/card3.png');
        margin-bottom: 24px;
      }

      &:nth-child(4),
      &:nth-child(5) {
        width: 708px;
        background-image: url('../../../assets/images/solution/government/card4.png');
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4) {
        margin-right: 24px;
      }
    }
  }
}
