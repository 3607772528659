.notfound-wrapper {
  position: relative;
  width: 1440px;
  margin: 0 auto;
  height: calc(100vh - 306px);

  img {
    position: absolute;
    top: calc((100% - 406px) / 2);
    left: 297px;
  }

  p {
    position: absolute;
    top: calc((100% - 406px) / 2 + 350px);
    // bottom: 40px;
    width: 100%;
    text-align: center;
    color: #8f9cb0;
    font-size: 16px;
  }
}
