.nav {
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  width: 100%;
  height: 64px;
  padding: 10px 52px;
  box-shadow: 0px 1px 0px 0px rgba(64, 71, 79, 0.1);
  transition: all 0.3s ease-in-out;
  min-width: 1440px;

  &::before {
    position: fixed;
    width: 100%;
    height: 64px;
    content: '';
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
    z-index: -1;
  }

  &.isFixed {
    background-color: #fff;
    transition: all 0.3s ease-in-out;
  }

  img {
    margin-right: 52px;
  }

  .ant-menu {
    border-bottom: none;
    background: none;
    font-size: 16px;
    width: calc(100% - 160px);

    .ant-menu-item,
    .ant-menu-submenu {
      padding: 0 36px;

      &::after {
        display: none;
      }

      &.ant-menu-item-active,
      &.ant-menu-submenu-active {
        a {
          color: #3370ed;
        }
      }
    }
  }
}

.ant-menu-submenu-popup {
  .ant-menu-vertical {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
    min-width: 152px !important;

    .ant-menu-item {
      width: 100% !important;
      margin: 0 !important;
      border-radius: 0 !important;

      &:hover {
        color: #3370ed !important;
        background-color: #e1eafc !important;
      }
    }
  }
}

.ant-menu-submenu > .ant-menu {
  border-radius: 0 !important;
}

.ant-menu-title-content {
  position: relative;
  display: block;

  a {
    display: block;
    color: #2e2f32!important;

    &.active {
      color: #3370ed!important;
    }
  }

  img {
    margin-left: 4px;
    vertical-align: super;
  }

  &:hover {
    a {
      color: #3370ed !important;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      border-bottom: 2px solid #1677ff;
      transition: border-color 0.3s
        cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
      left: 0;
    }
  }
}

.ant-menu-submenu-open {
  .ant-menu-title-content {
    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      border-bottom: 2px solid #1677ff;
      transition: border-color 0.3s
        cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
      left: 0;
    }
  }
}

.ant-menu-light > .ant-menu.ant-menu-sub .ant-menu-item-selected,
.ant-menu-light .ant-menu-sub .ant-menu-item-active {
  color: #3370ed !important;
  background-color: rgba(225, 234, 252, 0.5) !important;

  .ant-menu-title-content, 
  a {
    color: #3370ed !important;
  }
}

.ant-menu-light.ant-menu-horizontal  > .ant-menu-item-selected, 
.ant-menu-light .ant-menu-item-selected {
  color: #2e2f32 !important;
  background-color: transparent !important;
}
