.ying-series-wrapper {
  .banner {
    background-image: url('../../../assets/images/production/fengSeries/banner2.2.png');
  }

  .series-container {
    background-image: url('../../../assets/images/production/yingSeries/bg.png');
    height: 843px;
    padding-top: 67px;

    .series-content {
      height: 703px;

      .series-left {
        .introduction-title {
          width: 827px;
          height: 290px;
          background-image: url('../../../assets/images/production/yingSeries/bg-item.png');
          padding-top: 111px;
          padding-left: 89px;
          margin-left: 46px;
          margin-top: -16px;
        }

        .introduction-container {
          margin-left: 142px;

          .introduction-item {
            width: 513px;
            height: 152px;
            background-color: #eef1f7;
            background-image: url('../../../assets/images/production/yingSeries/bg-normal.png');
            background-size: cover;
            display: flex;
            align-items: center;

            .img {
              margin-right: 42px;
            }

            p {
              line-height: 37px;
              font-size: 16px;
              margin: 0;
            }

            &:nth-child(1) {
              padding-left: 51px;
              margin-bottom: 40px;

              .img {
                width: 40px;
                height: 46px;
                background-image: url('../../../assets/images/production/yingSeries/icon01.png');
              }
            }
            &:nth-child(2) {
              padding-left: 50px;

              .img {
                width: 46px;
                height: 46px;
                background-image: url('../../../assets/images/production/yingSeries/icon02.png');
              }
            }

            &:hover {
              background-image: url('../../../assets/images/production/yingSeries/bg-hover.png');
              cursor: default;
              color: #fff;

              &:nth-child(1) {
                .img {
                  background-image: url('../../../assets/images/production/yingSeries/icon01-hover.png');
                }
              }

              &:nth-child(2) {
                .img {
                  background-image: url('../../../assets/images/production/yingSeries/icon02-hover.png');
                }
              }
            }
          }
        }
      }

      .series-right {
        padding-top: 40px;
        padding-right: 158px;
      }
    }
  }
}
