.datagovernance-wrapper {

  .banner {
    background-image: url('../../../assets/images/production/bigdata/banner2.1.png');

    .content {
      width: 610px!important;
    }
  }

  .datagovernance-container {

    .introduction-container {

      .introduction-title {
        background-image: url('../../../assets/images/production/dataGovernance/bg.png');

        .title-container {

          & > div {
            top: -20px;
            left: 54px;
            width: 1203px;
            height: 388px;
            background-image: url('../../../assets/images/production/dataGovernance/bg-item.png');
          }
        }
      }

      .introduction-content {
        padding-top: 77px;
        padding-bottom: 40px;
        position: relative;
        text-align: center;
        background-color: #f5f7fc;

        img {
          &:nth-child(2) {
            position: absolute;
            right: 222px;
            bottom: 92px;
          }
        }
      }
    }

    .feature-container {
      & > p {
        height: 132px;
        margin: 0;
        position: relative;
        font-size: 28px;
        color: #0e1011;
        line-height: 132px;
        text-align: center;
      }

      .feature-content {
        display: flex;
        width: 1440px;
        margin: 0 auto;
        padding-bottom: 120px;

        .feature-item {
          width: 278px;
          height: 256px;
          background-color: #eef1f7;
          background-image: url('../../../assets/images/production/dataGovernance/featurebg.png');
          background-size: cover;
          transition: all 0.3s ease-in-out;

          .img {
            transition: all 0.3s ease-in-out;
          }

          &:not(:last-child) {
            margin-right: 13px;
          }

          p {
            margin-top: 28px;
          }

          &:nth-child(1) {
            padding-top: 40px;
            padding-left: 29px;

            .img {
              width: 44px;
              height: 44px;
              background-image: url('../../../assets/images/production/dataGovernance/icon01.png');
            }

            p {
              width: 224px;
              &:nth-of-type(2) {
                margin-top: 0;
              }
            }
          }

          &:nth-child(2) {
            padding-top: 37px;
            padding-left: 27px;

            .img {
              width: 36px;
              height: 42px;
              background-image: url('../../../assets/images/production/dataGovernance/icon02.png');
            }

            p {
              width: 210px;
            }
          }

          &:nth-child(3) {
            padding-top: 36px;
            padding-left: 30px;

            .img {
              width: 45px;
              height: 45px;
              background-image: url('../../../assets/images/production/dataGovernance/icon03.png');
            }

            p {
              width: 210px;
            }
          }

          &:nth-child(4) {
            padding-top: 33px;
            padding-left: 45px;

            .img {
              width: 42px;
              height: 38px;
              background-image: url('../../../assets/images/production/dataGovernance/icon04.png');
            }

            p {
              width: 210px;
            }
          }

          &:nth-child(5) {
            padding-top: 33px;
            padding-left: 45px;

            .img {
              width: 36px;
              height: 42px;
              background-image: url('../../../assets/images/production/dataGovernance/icon05.png');
            }

            p {
              width: 210px;
            }
          }

          &:not(:first-child) {
            p {
              &:nth-of-type(1) {
                color: #0e1011;
                font-size: 20px;
                margin-top: 24px;
              }
            }
          }

          &:hover {
            background-image: url('../../../assets/images/production/dataGovernance/featurebghover.png');
            cursor: default;
            transition: all 0.3s ease-in-out;

            .img {
              transition: all 0.3s ease-in-out;
            }

            p {
              color: #fff !important;
            }

            &:nth-child(1) {
              .img {
                background-image: url('../../../assets/images/production/dataGovernance/icon01-hover.png');
              }
            }

            &:nth-child(2) {
              .img {
                background-image: url('../../../assets/images/production/dataGovernance/icon02-hover.png');
              }
            }

            &:nth-child(3) {
              .img {
                background-image: url('../../../assets/images/production/dataGovernance/icon03-hover.png');
              }
            }

            &:nth-child(4) {
              .img {
                background-image: url('../../../assets/images/production/dataGovernance/icon04-hover.png');
              }
            }

            &:nth-child(5) {
              .img {
                background-image: url('../../../assets/images/production/dataGovernance/icon05-hover.png');
              }
            }
          }
        }
      }
    }
  }
}
