.for-ana-wrapper {
  .banner {
    background-image: url('../../../assets/images/production/fengSeries/banner2.2.png');
  }

  .series-container {
    height: 987px;
    background-image: url('../../../assets/images/production/forAnaSeries/titlebg.png');
    padding-top: 67px;

    .series-content {
      height: 843px;
      box-shadow: 0px 1px 38px 0px rgba(112, 150, 207, 0.25);

      & > div {
        height: fit-content !important;
        padding-top: 58px;
        padding-right: 100px;
        box-shadow: unset !important;
      }

      .introduction-title {
        width: 693px;
        height: 329px;
        background-image: url('../../../assets/images/production/forAnaSeries/bg-item.png');
        padding-top: 111px;
        padding-left: 89px;
        margin-left: 46px;
        margin-top: -76px;

        p {
          &:nth-child(2) {
            width: 593px !important;
          }
        }
      }
    }

    .introduction-container {
      width: 100%!important;
      display: flex;
      flex-wrap: wrap;
      padding: 86px 90px 79px!important;
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(8px);

      .introduction-item {
        width: calc((100% - 32px) / 2);
        height: 152px;
        background-color: #eef1f7;
        background-image: url('../../../assets/images/production/forAnaSeries/bg.png');
        background-size: cover;
        display: flex;
        align-items: center;

        .img {
          margin-right: 42px;
        }

        p {
          line-height: 37px;
          font-size: 16px;
          margin: 0;

          &:nth-child(2) {
            font-size: 14px;
            color: #5a5e66;
            width: 326px;
          }
        }

        &:nth-child(1),
        &:nth-child(3) {
          margin-right: 32px;
        }

        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 30px;
        }

        &:nth-child(1) {
          padding-left: 51px;

          .img {
            width: 36px;
            height: 42px;
            background-image: url('../../../assets/images/production/forAnaSeries/icon01.png');
          }
        }

        &:nth-child(2) {
          padding-left: 50px;

          .img {
            width: 42px;
            height: 38px;
            background-image: url('../../../assets/images/production/forAnaSeries/icon02.png');
          }
        }

        &:nth-child(3) {
          padding-left: 50px;

          .img {
            width: 45px;
            height: 45px;
            background-image: url('../../../assets/images/production/forAnaSeries/icon03.png');
          }
        }

        &:nth-child(4) {
          padding-left: 54px;

          .img {
            width: 39px;
            height: 37px;
            background-image: url('../../../assets/images/production/forAnaSeries/icon04.png');
          }
        }

        &:hover {
          background-image: url('../../../assets/images/production/forAnaSeries/bg-hover.png');
          cursor: default;
          color: #fff;

          p {
            color: #fff;
          }

          &:nth-child(1) {
            .img {
              background-image: url('../../../assets/images/production/forAnaSeries/icon01-hover.png');
            }
          }

          &:nth-child(2) {
            .img {
              background-image: url('../../../assets/images/production/forAnaSeries/icon02-hover.png');
            }
          }

          &:nth-child(3) {
            .img {
              background-image: url('../../../assets/images/production/forAnaSeries/icon03-hover.png');
            }
          }

          &:nth-child(4) {
            .img {
              background-image: url('../../../assets/images/production/forAnaSeries/icon04-hover.png');
            }
          }
        }
      }
    }
  }
}
