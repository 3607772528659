.culture-wrapper {
  background-color: #f2f6fb;

  p {
    margin: 0;
  }

  .title-wrapper {
    font-size: 48px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 4.8px;
    color: #fff;
    height: 200px;
    line-height: 200px;
    background-image: url('../../../assets/images/about/culture/top-bg.png');
    background-size: cover;
    min-width: 1440px;
    background-position: center center;
  }

  .idea-wrapper {
    background-image: url('../../../assets/images/about/culture/idea-bg.png');
    background-position: left top;
    background-repeat: no-repeat;
    background-color: #f2f6fb;

    & > p {
      width: 1440px;
      margin: 0 auto;
      font-size: 28px;
      padding: 40px 0 24px;
      text-align: center;
    }

    .idea-content {
      width: 1440px;
      margin: 0 auto;
      display: flex;
      padding-left: 10px;
      padding-bottom: 85px;

      img {
        flex: 0 1 auto;

        &:nth-of-type(1) {
          height: 76px;
          margin-top: 13px;
        }
      }

      .content {
        font-size: 16px;
        line-height: 26px;
        color: #878d99;
        padding-top: 90px;
        padding-left: 46px;
        padding-right: 145px;
      }
    }

    .pyramid-wrapper {
      width: 1440px;
      margin: 0 auto;
      display: flex;
      height: 564px;

      & > div {
        box-shadow: -2px 0px 30px 0px rgba(35, 79, 209, 0.1);
        position: relative;

        & > .title {
          position: absolute;
          left: 26px;
          top: 0;
          width: 68px;
          height: 132px;
          padding: 24px;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: 2px;
          color: #fff;
          background-image: url('../../../assets/images/about/culture/pyramid-title.png');
        }
      }

      .mission-wrapper,
      .vision-wrapper {
        width: 217px;
        margin-right: 3px;

        .content {
          position: absolute;
          top: 75px;
          right: 62px;
          width: 24px;
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 1.8px;
          color: #7a8ba6;
          word-break: break-word;
        }
      }

      .mission-wrapper {
        background-image: url('../../../assets/images/about/culture/mission-bg.png');
      }

      .vision-wrapper {
        background-image: url('../../../assets/images/about/culture/vision-bg.png');
      }

      .strategy-wrapper {
        flex: 1;
        padding-top: 65px;
        padding-left: 83px;
        background: #fff;

        .strategy-content {
          .title {
            font-size: 24px;
            line-height: 24px;
            padding-bottom: 18px;
            font-weight: bold;
            color: #7a8ba6;
            text-align: center;
          }
        }

        & > img {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  .values-wrapper {
    width: 1440px;
    margin: 0 auto;
    padding-top: 74px;

    & > p {
      text-align: center;

      &:nth-child(1) {
        font-size: 28px;
        line-height: 28px;
        color: #0e1011;
        padding-bottom: 17px;
      }

      &:nth-child(2) {
        font-size: 16px;
        line-height: 16px;
        color: #878d99;
        padding-bottom: 19px;
      }
    }

    .ant-tabs {
      box-shadow: -2px 0px 30px 0px rgba(35, 79, 209, 0.2);
      background-color: #fff;
    }

    .ant-tabs-tab {
      width: 180px;
      height: 120px;
      background-color: #f2f6fb;
      color: #0e1011;
      font-size: 18px;

      &.ant-tabs-tab-active {
        background-color: #fff;
        font-weight: bold;

        .ant-tabs-tab-btn {
          color: #0e1011 !important;
        }
      }

      &:hover {
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
        .ant-tabs-tab-btn {
          color: #3370ed !important;
        }
      }
    }

    .ant-tabs-ink-bar {
      right: unset;
      left: 0;
    }

    .values-container {
      position: relative;
      height: 480px;
      background-image: url('../../../assets/images/about/culture/values-bg.png');
      background-position: right bottom;
      background-repeat: no-repeat;
      padding-top: 152px;
      padding-left: 166px;

      .content {
        width: 800px;
        font-size: 16px;
        color: #878d99;

        p {
          margin-bottom: 24px;
        }
      }

      img {
        position: absolute;
        right: 63px;
        bottom: 20px;
      }
    }
  }

  .pillar-wrapper {
    width: 1440px;
    margin: 0 auto;
    padding-top: 86px;
    padding-bottom: 100px;

    & > p {
      text-align: center;

      &:nth-child(1) {
        font-size: 28px;
        line-height: 28px;
        color: #0e1011;
        padding-bottom: 17px;
      }

      &:nth-child(2) {
        font-size: 16px;
        line-height: 16px;
        color: #878d99;
        padding-bottom: 20px;
      }
    }

    .pillar-container {
      display: flex;
      height: 493px;

      .pillar-item {
        width: 202px;
        margin-right: 1px;
        box-shadow: 0px 0px 30px 0px rgba(35, 79, 209, 0.1);
        padding-top: 102px;
        padding-left: 56px;
        transition: width 0.3s ease 0s;

        &:nth-child(1) {
          background-image: url('../../../assets/images/about/culture/pillar1.png');
        }
        &:nth-child(2) {
          background-image: url('../../../assets/images/about/culture/pillar2.png');
        }
        &:nth-child(3) {
          background-image: url('../../../assets/images/about/culture/pillar3.png');
        }
        &:nth-child(4) {
          background-image: url('../../../assets/images/about/culture/pillar4.png');
        }

        ul {
          display: none;
          padding-left: 0;
        }

        .title {
          font-size: 24px;
          line-height: 24px;
          color: #0e1011;
          padding-bottom: 40px;
        }

        &.active {
          width: 826px;
          transition: width 0.3s ease 0s;

          .title {
            color: #fff;
          }

          &:nth-child(1) {
            background-image: url('../../../assets/images/about/culture/pillar1-active.png');
          }
          &:nth-child(2) {
            background-image: url('../../../assets/images/about/culture/pillar2-active.png');
          }
          &:nth-child(3) {
            background-image: url('../../../assets/images/about/culture/pillar3-active.png');
          }
          &:nth-child(4) {
            background-image: url('../../../assets/images/about/culture/pillar4-active.png');
          }

          ul {
            display: block;
            font-size: 16px;
            color: #d8e5ff;
            line-height: 24px;
            width: 696px;

            li {
              list-style-type: none;
              margin-bottom: 12px;
              position: relative;
              padding-left: 16px;

              &::before {
                content: '';
                display: inline-block;
                width: 6px;
                height: 6px;
                background-image: url('../../../assets/images/about/culture/dot.png');
                position: absolute;
                left: 0;
                top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
