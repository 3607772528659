.introduction-wrapper {
  background-image: url('../../../assets/images/about/introduction/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 120px;
  padding-bottom: 95px;
  min-width: 1440px;
  background-position: center center;

  .title {
    width: 1440px;
    font-size: 32px;
    color: #2d2f33;
    margin: 0 auto 56px;
  }

  .content {
    width: 1440px;
    margin: 0 auto;
    p {
      width: 1040px;
      font-size: 16px;
      line-height: 27px;
      color: #5a5e66;
      margin-bottom: 24px;

      &:nth-child(3) {
        font-weight: bold;
        color: #3370ed;
      }
    }
  }

  .introduction-container {
    width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding-top: 86px;
    padding-right: 400px;

    .introduction-item {
      width: 260px;
      flex: 0 1 auto;
      margin-bottom: 32px;

      p {
        margin-bottom: 0;

        span {
          color: #2d2f33;
          line-height: 36px;

          &:first-child {
            font-size: 36px;
          }

          &:last-child {
            font-size: 18px;
          }
        }

        &:nth-child(2) {
          color: #878d99;
          font-size: 16px;
        }
      }
    }
  }
}
