.support-wrapper {
  position: relative;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-image: url('../../assets/images/support/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-family: 'MicrosoftYaHei-Bold';
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 18px;
      color: #5a5e66;
      // width: 520px;
      margin-bottom: 26px;
    }
  }

  .support-container {
    .service-container {
      background-color: #f2f6fb;
      padding-bottom: 114px;

      > div {
        margin: 60px auto;
        width: 1440px;
        display: flex;
        flex-wrap: wrap;

        .service-item {
          box-shadow: 0px 4px 30px 0px
            rgba(35, 79, 209, 0.08);
          width: 704px;
          height: 334px;
          background-image: url('../../assets/images/support/down.png');
          background-size: cover;

          > .title {
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            height: 64px;
            line-height: 64px;
            background-size: cover;
            margin: 0;
          }

          &:nth-child(1) {
            margin-right: 32px;
            margin-bottom: 32px;

            > .title {
              background-image: url('../../assets/images/support/01bg.png');
            }

            .service-main {
              padding: 36px 32px 32px;
              > p {
                margin: 0;
                text-align: center;
                &:nth-child(1) {
                  font-size: 24px;
                  line-height: 24px;
                  color: #3370ed;
                  padding-bottom: 18px;
                }
                &:nth-child(2) {
                  font-size: 20px;
                  color: #0e1011;
                  padding-bottom: 32px;
                }
              }

              > div {
                span {
                  display: inline-block;
                  width: calc((100% - 12px) / 2);
                  height: 48px;
                  border-radius: 4px;
                  background-color: rgba(
                    221,
                    236,
                    255,
                    0.6
                  );
                  line-height: 48px;
                  color: #0e1011;
                  padding-left: 32px;

                  &:nth-child(1),
                  &:nth-child(3) {
                    margin-right: 12px;
                  }

                  &:nth-child(1),
                  &:nth-child(2) {
                    margin-bottom: 10px;
                  }
                }

                img {
                  margin-right: 12px;
                }
              }
            }
          }

          &:nth-child(2) {
            margin-bottom: 32px;

            > .title {
              background-image: url('../../assets/images/support/02bg.png');
            }

            .service-main {
              display: flex;

              > div {
                display: inline-block;
                width: 50%;
                text-align: center;
                padding-top: 54px;

                p {
                  margin: 0;

                  &:nth-of-type(1) {
                    color: #0e1011;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 18px;
                    padding: 18px 0;
                  }

                  &:nth-of-type(2),
                  &:nth-of-type(3) {
                    color: #878d99;
                    font-size: 14px;
                    line-height: 18px;
                  }
                }
              }
            }
          }

          &:nth-child(3) {
            margin-right: 32px;

            > .title {
              background-image: url('../../assets/images/support/03bg.png');
            }

            .service-main {
              padding: 30px 32px;

              > div {
                display: inline-block;
                width: calc((100% - 24px) / 3);
                background-image: linear-gradient(
                  0deg,
                  rgba(231, 242, 254, 0.5) 0%,
                  rgba(231, 242, 254, 1) 100%
                );
                border-radius: 3px;
                height: 98px;
                padding-top: 30px;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                  margin-bottom: 10px;
                }

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(5) {
                  margin-right: 12px;
                }

                p {
                  text-align: center;
                  margin: 0;

                  &:nth-child(1) {
                    color: #0e1011;

                    span {
                      &:nth-child(1) {
                        font-size: 28px;
                        line-height: 28px;
                      }

                      &:nth-child(2) {
                        font-size: 14px;
                      }
                    }
                  }

                  &:nth-child(2) {
                    color: #878d99;
                    font-size: 14px;
                  }
                }
              }
            }
          }

          &:nth-child(4) {
            > .title {
              background-image: url('../../assets/images/support/04bg.png');
            }

            .service-main {
              padding: 32px;
              span {
                display: inline-block;
                background-image: linear-gradient(
                  0deg,
                  rgba(231, 242, 254, 0.5) 0%,
                  rgba(231, 242, 254, 1) 100%
                );
                border-radius: 3px;
                height: 98px;
                line-height: 98px;
                padding-left: 40px;
                padding-right: 32px;

                img {
                  margin-right: 12px;
                }

                &:nth-child(1),
                &:nth-child(3) {
                  margin-right: 12px;
                  width: 240px;
                }

                &:nth-child(2),
                &:nth-child(4) {
                  width: calc(100% - 252px);
                }

                &:nth-child(1),
                &:nth-child(2) {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }

    .values-container {
      height: 658px;
      background-image: url('../../assets/images/support/bg.png');
      background-size: cover;
      box-shadow: inset 0px -7px 98px 0px rgba(28, 69, 154, 0.05);
      .title {
        margin-bottom: 80px;
      }

      & > div {
        display: flex;
        flex-wrap: wrap;
        width: 1440px;
        margin: 0 auto;

        .values-item {
          background-color: #164bb9;
          color: #fff;
          width: 358px;
          height: 163px;
          padding: 32px;
          margin: 1px;

          &:nth-child(1) {
            background-image: url('../../assets/images/support/01.png');
          }
          &:nth-child(2) {
            background-image: url('../../assets/images/support/02.png');
          }
          &:nth-child(3) {
            background-image: url('../../assets/images/support/03.png');
          }
          &:nth-child(4) {
            background-image: url('../../assets/images/support/04.png');
          }
          &:nth-child(5) {
            background-image: url('../../assets/images/support/05.png');
          }
          &:nth-child(6) {
            background-image: url('../../assets/images/support/06.png');
          }
          &:nth-child(7) {
            background-image: url('../../assets/images/support/07.png');
          }
          &:nth-child(8) {
            background-image: url('../../assets/images/support/08.png');
          }

          p {
            margin: 0;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }
    }

    .service-container,
    .values-container {
      padding-top: 66px;
      > .title {
        color: #0e1011;
        font-size: 28px;
        line-height: 28px;
        text-align: center;
      }
    }
  }
}
