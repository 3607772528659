.production-wrapper {
  position: relative;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-image: url('../../assets/images/production/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-size: 48px;
      letter-spacing: 4px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 24px;
      color: #5a5e66;
      // width: 500px;
      margin-bottom: 26px;
    }
  }

  .production-container {
    padding-top: 68px;

    .production-label {
      width: 240px;
      text-align: center;
      transition: all 0.3s ease-in-out;

      img {
        margin-bottom: 16px;
      }

      span {
        display: block;
        font-size: 18px;
        color: #5a5e66;
      }

      &:hover {
        transition: all 0.3s ease-in-out;

        span {
          color: #3370ed;
        }

        img {
          margin-top: -8px;
          margin-bottom: 24px;
        }
      }
    }

    .ant-tabs-tab-active {
      span {
        color: #3370ed;
      }
    }

    .ant-tabs-nav {
      margin: 0;
      &::before {
        display: none;
      }
    }

    .ant-tabs-content {
      padding-top: 28px;
      padding-bottom: 80px;

      .ant-tabs-tabpane {
        width: 1440px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
      }
    }

    .production-item {
      width: calc((100% - 12px) / 2);
      padding: 50px 40px;
      position: relative;
      min-height: 320px;
      background-size: cover;
      background-repeat: no-repeat;
      transition: all 0.3s ease-in-out;

      .production-content {
        & > div {
          div {
            height: 36px;
            color: #2d2f33;
            font-size: 32px;
            margin-bottom: 30px;
          }

          p {
            max-width: 400px;
            line-height: 26px;
            color: #878d99;
            margin: 0;
          }
        }
      }

      .see-detail {
        width: 95px;
        height: 32px;
        font-size: 14px;
        color: #3370ed;
        line-height: 30px;
        text-align: center;
        border: 1px solid #3370ed;
        position: absolute;
        left: 40px;
        bottom: 50px;
        transition: all 0.3s ease-in-out;

        .arrow {
          display: inline-block;
          width: 9px;
          height: 10px;
          background-image: url('../../assets/images/production/arrow1.png');
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &:hover {
        transition: all 0.3s ease-in-out;

        .see-detail {
          transition: all 0.3s ease-in-out;
          background-color: #3370ed;
          color: #fff;

          .arrow {
            background-image: url('../../assets/images/production/arrow2.png');
            transition: all 0.3s ease-in-out;
          }
        }
      }

      &:only-child {
        width: 100%;
        margin: 0;

        .production-content {
          & > div {
            p {
              max-width: 930px;
            }
          }
        }
      }
    }

    [id*='panel-BigData'] {
      .production-item {
        &:nth-child(1) {
          margin: 0 12px 12px 0;
          background-image: url('../../assets/images/production/card1.png');

          &:hover {
            background-image: url('../../assets/images/production/card1-hover.png');
          }
        }

        &:nth-child(2) {
          margin-bottom: 12px;
          background-image: url('../../assets/images/production/card2.png');

          &:hover {
            background-image: url('../../assets/images/production/card2-hover.png');
          }
        }

        &:nth-child(3) {
          margin-right: 12px;
          background-image: url('../../assets/images/production/card3.png');

          &:hover {
            background-image: url('../../assets/images/production/card3-hover.png');
          }
        }

        &:nth-child(4) {
          background-image: url('../../assets/images/production/card4.png');

          &:hover {
            background-image: url('../../assets/images/production/card4-hover.png');
          }
        }
      }
    }

    [id*='panel-ElectronicForensicsProducts'] {
      .production-item {
        &:nth-child(1) {
          margin: 0 12px 12px 0;
          background-image: url('../../assets/images/production/card5.png');

          &:hover {
            background-image: url('../../assets/images/production/card5-hover.png');
          }
        }

        &:nth-child(2) {
          margin-bottom: 12px;
          background-image: url('../../assets/images/production/card6.png');

          &:hover {
            background-image: url('../../assets/images/production/card6-hover.png');
          }
        }

        &:nth-child(3) {
          margin-right: 12px;
          background-image: url('../../assets/images/production/card7.png');

          &:hover {
            background-image: url('../../assets/images/production/card7-hover.png');
          }
        }

        &:nth-child(4) {
          background-image: url('../../assets/images/production/card8.png');

          &:hover {
            background-image: url('../../assets/images/production/card8-hover.png');
          }
        }
      }
    }

    [id*='panel-DataAnalysisService'] {
      .production-item {
        background-image: url('../../assets/images/production/card9.png');
        &:hover {
          background-image: url('../../assets/images/production/card9-hover.png');
        }
      }
    }

    [id*='panel-DataOperationService'] {
      .production-item {
        background-image: url('../../assets/images/production/card10.png');
        &:hover {
          background-image: url('../../assets/images/production/card10-hover.png');
        }
      }
    }
  }
}

.production-introduction-wrapper {
  position: relative;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 26px;
      color: #878d99;
      width: 580px;
    }
  }

  .introduction-container {
    & > p {
      height: 132px;
      margin: 0;
      position: relative;
      font-size: 28px;
      color: #0e1011;
      line-height: 132px;
      text-align: center;

      img {
        position: absolute;
        right: 193px;
        top: 25px;
      }
    }

    .introduction-title {
      height: 437px;
      background-size: cover;
      position: relative;

      .title-wrapper {
        width: 1440px;
        margin: 0 auto;
        position: relative;
        padding-top: 67px;

        & > img {
          position: absolute;
          top: 96px;
        }
      }

      .title-container {
        width: 1160px;
        height: 302px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 1px 38px 0px
          rgba(112, 150, 207, 0.25);
        backdrop-filter: blur(8px);

        & > div {
          position: absolute;
          padding-top: 92px;
        }
      }

      p {
        max-width: 800px;
        margin-left: 110px;

        &:nth-of-type(1) {
          span {
            &:nth-child(1) {
              // font-weight: bolder;
              font-size: 40px;
              letter-spacing: 5px;
              background: linear-gradient(
                to right,
                #765fff,
                #3370ed
              );
              -webkit-background-clip: text;
              color: transparent;
            }
            &:nth-child(2) {
              font-size: 16px;
              line-height: 30px;
              color: #5a5e66;
            }
          }
        }

        &:nth-of-type(2) {
          font-size: 16px;
          line-height: 30px;
          color: #5a5e66;
        }
      }
    }
  }

  .feature-item,
  .feature-item:hover {
    transition: all 0.3s ease-in-out;

    // .img {
    //   transition: all 0.3s ease-in-out;
    // }
  }
}

.production-series-wrapper {
  position: relative;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 26px;
      color: #878d99;
      width: 580px;
    }
  }

  .series-container {
    position: relative;
    background-size: cover;

    .series-content {
      position: relative;
      width: 1440px;
      margin: 0 auto;

      & > img {
        position: absolute;
        left: -102px;
        top: -15px;
      }

      & > div {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        display: flex;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 1px 38px 0px
          rgba(112, 150, 207, 0.25);
        backdrop-filter: blur(8px);
        justify-content: space-between;
      }

      .introduction-title {
        p {
          &:nth-child(1) {
            span {
              &:nth-child(1) {
                // font-weight: bold;
                font-size: 40px;
                letter-spacing: 5px;
                background: linear-gradient(
                  to right,
                  #765fff,
                  #3370ed
                );
                -webkit-background-clip: text;
                color: transparent;
              }

              &:nth-child(2) {
                font-size: 16px;
                line-height: 30px;
                color: #5a5e66;
              }
            }
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 645px;
            font-size: 16px;
            line-height: 30px;
            color: #5a5e66;
          }
        }
      }
    }
  }

  .introduction-item,
  .introduction-item:hover {
    transition: all 0.3s ease-in-out;
  }
}
