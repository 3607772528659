.data-ana-wrapper {
  .banner {
    background-image: url('../../../assets/images/production/dataAna/banner2.3.png');
  }

  .series-container {
    background-image: url('../../../assets/images/production/dataAna/bg.png');
    height: 553px;
    padding-top: 36px;

    .series-content {
      height: 480px;

      & > img {
        top: 16px !important;
      }

      .introduction-title {
        width: 853px;
        height: 377px;
        background-image: url('../../../assets/images/production/dataAna/bg-item.png');
        padding-top: 94px;
        padding-left: 65px;
        margin-top: 30px;
        margin-left: 35px;

        p {
          width: 720px !important;
        }
      }

      .series-right {
        padding-top: 140px;
        padding-right: 95px;
      }
    }
  }

  .series-bottom {
    height: 908px;
    background-image: url('../../../assets/images/production/dataAna/bg2.png');
    background-size: cover;
    padding-top: 112px;
    text-align: center;
  }
}
