.digital-wrapper {
  position: relative;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-image: url('../../../assets/images/solution/digital/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-family: 'MicrosoftYaHei-Bold';
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 26px;
      color: #878d99;
      width: 580px;
      margin-bottom: 26px;
    }
  }

  .introduction-wrapper,
  .achievement-wrapper {
    .title {
      font-size: 28px;
      color: #2e2f32;
      line-height: 28px;
      margin: 0 auto;
      text-align: center;
      padding-top: 80px;
      padding-bottom: 40px;
    }
  }

  .introduction-wrapper {
    height: 688px;
    background-image: url('../../../assets/images/solution/digital/bg1.png');
    background-size: cover;
    padding: 0;

    .introduction-title {
      width: 1440px;
      height: 460px;
      background-image: url('../../../assets/images/solution/digital/card1.png');
      background-size: cover;
      margin: 0 auto;
      padding-top: 113px;
      padding-left: 788px;

      p {
        padding-left: 22px;

        &:nth-of-type(1) {
          font-size: 24px;
          line-height: 24px;
          color: #fff;
          margin-top: -16px;
        }

        &:nth-of-type(2) {
          width: 492px;
          font-size: 16px;
          line-height: 26px;
          color: #b4bccc;
          margin-bottom: -7px;
        }
      }

      img {
        &:nth-of-type(2) {
          margin-left: 22px;
        }
      }
    }
  }

  .introduction-content {
    background-image: url('../../../assets/images/solution/digital/bg2.png');
    background-size: cover;
    height: 367px;
    display: flex;
    padding-top: 80px;
    justify-content: center;
    box-shadow: 0px 2px 30px 0px rgba(35, 79, 209, 0.1);

    .introduction-item {
      width: 351px;
      height: 207px;
      text-align: center;
      background-image: url('../../../assets/images/solution/digital/card2.png');
      padding-top: 40px;
      box-shadow: 0px 2px 30px 0px rgba(35, 79, 209, 0.5);

      &:not(:last-child) {
        margin-right: 12px;
      }

      p {
        margin: 0;
        font-size: 18px;
        color: #2e2f32;
        line-height: 26px;
      }

      img {
        display: block;
        margin: 0 auto;

      }

      &:nth-child(1) {
        img {
          margin-bottom: 21px;
        }
      }

      &:nth-child(2) {
        img {
          margin-bottom: 23px;
        }
      }

      &:nth-child(3) {
        img {
          margin-bottom: 28px;
        }
      }

      &:nth-child(4) {
        img {
          margin-bottom: 21px;
        }
      }
    }
  }

  .achievement-wrapper {
    padding-bottom: 80px;

    .achievement-container {
      width: 1440px;
      margin: 0 auto;
      display: flex;

      .achievement-item {
        width: 472px;
        height: 287px;
        background-color: #eef1f7;
        background-image: url('../../../assets/images/solution/digital/card3.png');
        padding-left: 40px;
        transition: all 0.3s ease-in-out;

        .title {
          text-align: left;
          font-size: 22px;
          color: #0e1011;
          line-height: 22px;
          padding: 28px 0 30px 0;
        }

        .sub-title {
          max-width: 324px;
          font-size: 14px;
          color: #5a5e66;
        }

        &:not(:last-child) {
          margin-right: 12px;
        }

        &:nth-child(1) {
          padding-top: 44px;
          .img {
            width: 40px;
            height: 40px;
            background-image: url('../../../assets/images/solution/digital/icon1.png');
          }
        }

        &:nth-child(2) {
          padding-top: 47px;
          .img {
            width: 41px;
            height: 34px;
            background-image: url('../../../assets/images/solution/digital/icon2.png');
          }
        }

        &:nth-child(3) {
          padding-top: 43px;
          .img {
            width: 36px;
            height: 42px;
            background-image: url('../../../assets/images/solution/digital/icon3.png');
          }
        }

        &:hover {
          background-image: url('../../../assets/images/solution/digital/card3-hover.png');
          transition: all 0.3s ease-in-out;

          .title,
          .sub-title {
            color: #fff;
          }

          &:nth-child(1) {
            .img {
              background-image: url('../../../assets/images/solution/digital/icon1-hover.png');
            }
          }

          &:nth-child(2) {
            .img {
              background-image: url('../../../assets/images/solution/digital/icon2-hover.png');
            }
          }

          &:nth-child(3) {
            .img {
              background-image: url('../../../assets/images/solution/digital/icon3-hover.png');
            }
          }
        }
      }
    }
  }
}
