.contract-wrapper {
  width: 1424px;
  height: 435px;
  margin: 80px auto;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 38px 0px rgba(112, 150, 207, 0.35);
  padding: 60px 72px;
  display: flex;

  .title {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: #2d2f33;
    margin-bottom: 30px;
  }

  .information-wrapper {
    width: 50%;

    .information-item {
      img {
        vertical-align: top;
      }

      &:nth-child(4) {
        img {
          margin-top: 3px;
        }
      }

      .information-content {
        display: inline-block;
        padding-left: 50px;

        p {
          &:nth-child(1) {
            font-size: 22px;
            color: #2d2f33;
            margin-bottom: 6px;
          }

          &:nth-child(2) {
            font-size: 16px;
            line-height: 24px;
            color: #5a5e66;
            margin-bottom: 26px;
          }
        }
      }
    }
  }

  .focus-wrapper {
    width: 50%;

    img {
      margin-bottom: 16px;
    }

    p {
      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 24px;
        color: #5a5e66;
      }
    }
  }
}
