.bigdata-wrapper {
  .banner {
    background-image: url('../../../assets/images/production/bigdata/banner2.1.png');

    .content {
      width: 610px!important;
    }
  }

  .bigdata-container {
    .introduction-container {
      .introduction-title {
        background-image: url('../../../assets/images/production/bigdata/01.png');

        .title-container {
          & > div {
            top: -20px;
            left: 54px;
            width: 1212px;
            height: 367px;
            background-image: url('../../../assets/images/production/bigdata/bg-item.png');
          }
        }
      }

      .introduction-content {
        padding-top: 114px;
        padding-bottom: 144px;
        position: relative;
        text-align: center;
        background-color: #f5f7fc;

        & > img {
          position: absolute;
          left: 122px;
          bottom: 182px;
        }

        & > div {
          padding: 36px;
          background: #fff;
          width: fit-content;
          margin: 0 auto;
          position: relative;
          box-shadow: 0px 1px 38px 0px
            rgba(112, 150, 207, 0.25);
        }
      }
    }

    .feature-container {
      & > p {
        height: 132px;
        margin: 0;
        position: relative;
        font-size: 28px;
        color: #0e1011;
        line-height: 132px;
        text-align: center;
      }

      .feature-content {
        display: flex;
        width: 1440px;
        margin: 0 auto;
        padding-bottom: 120px;

        .feature-item {
          width: 350px;
          height: 216px;
          background-color: #eef1f7;
          background-image: url('../../../assets/images/production/bigdata/bg.png');
          background-size: cover;

          &:not(:last-child) {
            margin-right: 13px;
          }

          p {
            margin-top: 28px;
          }

          &:nth-child(1) {
            padding-top: 33px;
            padding-left: 51px;

            .img {
              width: 36px;
              height: 42px;
              background-image: url('../../../assets/images/production/bigdata/icon01.png');
            }

            p {
              width: 240px;
            }
          }

          &:nth-child(2) {
            padding-top: 42px;
            padding-left: 46px;

            .img {
              width: 35px;
              height: 33px;
              background-image: url('../../../assets/images/production/bigdata/icon02.png');
            }

            p {
              width: 272px;
            }
          }

          &:nth-child(3) {
            padding-top: 36px;
            padding-left: 37px;

            .img {
              width: 44px;
              height: 44px;
              background-image: url('../../../assets/images/production/bigdata/icon03.png');
            }

            p {
              width: 272px;
            }
          }

          &:nth-child(4) {
            padding-top: 33px;
            padding-left: 45px;

            .img {
              width: 36px;
              height: 42px;
              background-image: url('../../../assets/images/production/bigdata/icon04.png');
            }

            p {
              width: 256px;
            }
          }

          &:hover {
            background-image: url('../../../assets/images/production/bigdata/bg-hover.png');
            cursor: default;
            color: #fff;

            &:nth-child(1) {
              .img {
                background-image: url('../../../assets/images/production/bigdata/icon01-hover.png');
              }
            }

            &:nth-child(2) {
              .img {
                background-image: url('../../../assets/images/production/bigdata/icon02-hover.png');
              }
            }

            &:nth-child(3) {
              .img {
                background-image: url('../../../assets/images/production/bigdata/icon03-hover.png');
              }
            }

            &:nth-child(4) {
              .img {
                background-image: url('../../../assets/images/production/bigdata/icon04-hover.png');
              }
            }
          }
        }
      }
    }
  }
}
