.strategy-wrapper {
  .top-wrapper {
    // min-height: 533px;

    img {
      width: 100%;
    }
  }

  p {
    margin: 0;
  }

  .goal-wrapper,
  .intelligent-wrapper,
  .carriage-wrapper,
  .field-wrapper {
    // padding-left: 338px;
    & > div {
      width: 1440px;
      margin: 0 auto;
    }
  }

  .goal-wrapper,
  .intelligent-wrapper {
    background: #fff;
  }

  .carriage-wrapper,
  .field-wrapper {
    background: #f2f6fb;
  }

  .goal-wrapper {
    height: 265px;
    padding-top: 44px;

    .goal-content {
      display: inline-block;
      margin-left: 100px;
      width: fit-content;

      p {
        font-size: 30px;
        background: linear-gradient(
          to right,
          #745ffe,
          #3370ed
        );
        -webkit-background-clip: text;
        color: transparent;
        font-weight: bold;
        padding-left: 121px;
        padding-right: 136px;
        line-height: 30px;
      }

      img {
        &:nth-of-type(2) {
          float: right;
        }
      }
    }
  }

  .carriage-wrapper {
    padding-bottom: 114px;

    & > div {
      & > img {
        margin-top: 154px;
        margin-right: 11px;
      }
    }

    .carriage-content {
      margin-left: 88px;
      margin-top: 118px;
      display: inline-flex;

      .platform-container,
      .tool-container {
        margin-right: 14px;
      }

      .title {
        font-size: 16px;
        line-height: 48px;
        color: #fff;
        text-align: center;
        font-weight: bold;
      }

      .content {
        font-size: 16px;
        line-height: 30px;
        color: #5a6d8d;
        text-align: center;
        background-color: #dce6f7;
        height: 190px;
      }

      .platform-container,
      .service-container {
        .title {
          width: 260px;
        }
        .content {
          padding-top: 64px;
        }
      }

      .platform-container {
        .title {
          background-image: url('../../../assets/images/about/strategy/platform-header.png');
        }
      }

      .service-container {
        .title {
          background-image: url('../../../assets/images/about/strategy/service-header.png');
        }
      }

      .tool-container {
        .title {
          width: 446px;
          background-image: url('../../../assets/images/about/strategy/tool-header.png');
        }

        .content {
          padding-top: 40px;
        }

        .tool-wrapper {
          display: flex;
          padding-top: 20px;
          justify-content: center;

          .tool-item {
            background: #ebf1fb;
            padding: 16px 8px;

            &:not(:last-child) {
              margin-right: 12px;
            }

            p {
              font-size: 14px;
              line-height: 24px;

              &:nth-child(1) {
                color: #2d2f33;
              }

              &:nth-child(2) {
                color: #5a6d8d;
              }
            }
          }
        }
      }
    }
  }

  .intelligent-wrapper {
    height: 362px;

    & > div {
      & > img {
        margin-top: 114px;
        margin-right: 106px;
      }
    }

    .intelligent-content {
      display: inline-flex;
      margin-top: 112px;

      .intelligent-item {
        width: 140px;
        margin-right: 58px;
        text-align: center;

        p {
          font-size: 14px;
          line-height: 22px;
          color: #878d99;

          &:nth-of-type(1) {
            padding-top: 20px;
            font-size: 18px;
            line-height: 36px;
            color: #2d2f33;
            font-weight: bold;
          }
        }

        &:nth-child(1) {
          padding-top: 8px;
        }

        &:nth-child(3) {
          padding-top: 4px;
        }

        &:nth-child(4) {
          padding-top: 2px;
        }

        &:nth-child(5) {
          padding-top: 16px;
        }
      }
    }
  }

  .field-wrapper {
    & > div {
      & > img {
        margin-top: 102px;
        margin-right: 100px;
        vertical-align: top;
      }
    }

    .field-content {
      margin-top: 93px;
      display: inline-block;
      padding-bottom: 50px;

      .field-top {
        display: flex;
        padding-left: 185px;
        margin-bottom: 48px;

        .field-item {
          text-align: center;

          &:not(:last-child) {
            margin-right: 150px;
          }

          & > div {
            width: 107px;
            height: 107px;
            background-color: #fff;
            box-shadow: 0px 0px 20px 0px
              rgba(66, 112, 206, 0.05);
            border-radius: 4px;
            line-height: 107px;
          }

          span {
            font-size: 18px;
            font-weight: bold;
            color: #2d2f33;
            line-height: 46px;
          }
        }
      }

      .field-bottom {
        .field-item {
          height: 144px;
          display: flex;
          background-color: #e3ebf8;

          &:nth-child(1),
          &:nth-child(2) {
            margin-bottom: 12px;
          }

          .title {
            position: relative;
            z-index: 1;
            font-size: 22px;
            color: #fff;
            line-height: 144px;
            width: 212px;
            padding-left: 29px;
            background-image: url('../../../assets/images/about/strategy/field-bg-l.png');
          }

          .content {
            width: 858px;
            font-size: 16px;
            line-height: 36px;
            color: #5a6d8d;
            background-size: cover;
            margin-left: -62px;
            padding: 36px 84px 0 110px;
          }

          &:nth-child(1) {
            .content {
              background-image: url('../../../assets/images/about/strategy/field-bg-r-1.png');
            }
          }
          &:nth-child(2) {
            .content {
              background-image: url('../../../assets/images/about/strategy/field-bg-r-2.png');
            }
          }
          &:nth-child(3) {
            .content {
              background-image: url('../../../assets/images/about/strategy/field-bg-r-3.png');
            }
          }
        }
      }
    }
  }
}
