.network-wrapper {
  position: relative;

  .banner {
    min-width: 1440px;
    height: 460px;
    background-image: url('../../../assets/images/solution/network/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 160px;
    padding-left: 240px;

    .title {
      font-family: 'MicrosoftYaHei-Bold';
      font-size: 48px;
      letter-spacing: 3.8px;
      color: #2d2f33;
      margin-bottom: 22px;
      font-weight: bold;
    }

    .content {
      font-size: 16px;
      line-height: 26px;
      color: #878d99;
      width: 580px;
      margin-bottom: 26px;
    }
  }

  .introduction-wrapper,
  .achievement-wrapper {
    .title {
      font-size: 28px;
      color: #2e2f32;
      line-height: 28px;
      margin: 0 auto;
      text-align: center;
      padding-top: 80px;
      padding-bottom: 40px;
    }
  }

  .introduction-wrapper {
    height: 688px;
    background-image: url('../../../assets/images/solution/network/bg1.png');
    background-size: cover;
    padding: 0;

    .introduction-title {
      width: 1440px;
      height: 460px;
      background-image: url('../../../assets/images/solution/network/card.png');
      background-size: cover;
      margin: 0 auto;
      padding-top: 113px;
      padding-left: 788px;

      p {
        padding-left: 22px;

        &:nth-of-type(1) {
          font-size: 24px;
          line-height: 24px;
          color: #fff;
          margin-top: -16px;
        }

        &:nth-of-type(2) {
          width: 492px;
          font-size: 16px;
          line-height: 26px;
          color: #b4bccc;
          margin-bottom: -7px;
        }
      }

      img {
        &:nth-of-type(2) {
          margin-left: 22px;
        }
      }
    }
  }

  .achievement-wrapper {
    background-image: url('../../../assets/images/solution/network/bg2.png');
    background-size: cover;
  }

  .achievement-container {
    width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 56px;

    .achievement-item {
      width: 342px;
      height: 130px;
      background-color: #fff;
      box-shadow: 0px 1px 12px 0px rgba(112, 150, 207, 0.2);
      text-align: center;
      padding-top: 16px;
      margin-bottom: 24px;

      span {
        padding-top: 16px;
        display: block;
        font-size: 16px;
        line-height: 18px;
        color: #0e1011;
      }

      &:not(:nth-child(4n)) {
        margin-right: 24px;
      }

      &:nth-last-child(2),
      &:nth-last-child(3) {
        height: 150px;

        span {
          width: 224px;
          text-align: center;
          margin: 0 auto;
          line-height: 20px;
        }
      }

      &:nth-last-child(3) {
        padding-top: 24px;
      }

      &:last-child {
        height: 150px;
        padding: 0;
        span {
          padding: 0;
          line-height: 150px;
        }
      }
    }
  }
}
